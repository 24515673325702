import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Store } from '@ngrx/store';
import { Response } from '@angular/http';

import { State } from './store/reducer';
import * as ArticlesActions from './store/actions';
import { Filter } from './models/filter.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  constructor(private http: Http,
    private store: Store<State>) {

      const me = this;

      this.store.select('articles').subscribe((state: any) => {
        if (state.loadingArticles) {
          me.getArticles().subscribe({
            next(res: Response) {
              me.store.dispatch({type: ArticlesActions.ActionTypes.ARTICLES_LOADED, payload: { result: res.json() }});
            }
          });
        }
      });
  }

  getArticles() {
    return this.http.get(environment.url + 'Article?' + this.prepareParameters());
  }

  getAutoComplete(value) {
    return this.http.get(environment.url + 'AutoComplete?' + this.prepareAutoCompleteParameters(value));
  }

  private prepareAutoCompleteParameters(value: string) {
    return 'query=' + encodeURIComponent(value) + '&language=' + Filter.Language;
  }

  private prepareParameters() {
    const parameters = [];
    if (Filter.Query && Filter.Query.length !== 0 && Filter.Query[0] !== '') {
      parameters.push('query=' + encodeURIComponent(Filter.Query.join(' ')));
    }
    if (Filter.Make && Filter.Make.Id) {
      parameters.push('make=' + Filter.Make.Id);
    }
    if (Filter.Model && Filter.Model.Id) {
      parameters.push('model=' + Filter.Model.Id);
    }
    if (Filter.Type && Filter.Type.Id) {
      parameters.push('type=' + Filter.Type.Id);
    }
    if (Filter.Group1 && Filter.Group1.Id) {
      parameters.push('group1=' + Filter.Group1.Id);
    }
    if (Filter.Group2 && Filter.Group2.Id) {
      parameters.push('group2=' + Filter.Group2.Id);
    }
    if (Filter.Group3 && Filter.Group3.Id) {
      parameters.push('group3=' + Filter.Group3.Id);
    }
    if (Filter.Offset) {
      parameters.push('offset=' + Filter.Offset);
    }
    if (Filter.Limit) {
      parameters.push('limit=' + Filter.Limit);
    }
    if (Filter.Language) {
      parameters.push('language=' + Filter.Language);
    }
    return parameters.join('&');
  }

}
