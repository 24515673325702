import { Component,
  OnInit,
  Input
} from '@angular/core';
import { ActivatedRoute,
  Router,
  ParamMap
} from '@angular/router';

import { Store } from '@ngrx/store';

import { State } from '../../store/reducer';

import * as ArticlesActions from '../../store/actions';
import { Filter } from '../../models/filter.model';

@Component({
  selector: 'app-selected-filter',
  templateUrl: './selected-filter.component.html',
  styleUrls: ['./selected-filter.component.scss']
})
export class SelectedFilterComponent implements OnInit {
  model = {
    Name: '',
    Id: 0
  };
  type = {
    Name: '',
    Id: 0
  };
  @Input() isMobile: boolean;

  private group = Filter.getAggregationAndId('group');

  constructor(private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles) {
        if (!state.togglingSidenav
          && state.result.Aggregations.ApplicationsAggregationItem.BaseItems.length !== 0
          && state.result.Aggregations.ApplicationsAggregationItem.AggregationLevel === 4) {
            this.group = Filter.getAggregationAndId('group');

            if (!state.filters.Make.Name) {
              Filter.setFilterFromItemGrandParent(state, 'Make', 'applications');
            }
            if (!state.filters.Model.Name) {
              Filter.setFilterFromItemParent(state, 'Model', 'applications');
            }
            if (!state.filters.Type.Name) {
              Filter.setFilterFromItemProper(state, 'Type', 'applications');
            }
            this.model = Filter.getValue('Model');
            this.type = Filter.getValue('Type');

            this.store.dispatch({type: ArticlesActions.ActionTypes.CHANGE_FILTERS, payload: {
              filters: Filter
            }});
        }
      }
    });
  }

  navigateUp() {
    if (Filter.Query.length) {
      this.router.navigate([Filter.Query.join(' '), '3', this.model.Id, this.group.lvl, this.group.id], {relativeTo: this.route.parent});
    } else {
      this.router.navigate(['3', this.model.Id, this.group.lvl, this.group.id], {relativeTo: this.route.parent});
    }
  }

}
