// moduł do routingu, nie wiem jeszcze jak działa

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainPanelComponent } from './main-panel/main-panel.component';
import { EmptyComponent } from './empty/empty.component';
import { MakeFilterComponent } from './filters-panel/make-filter/make-filter.component';
import { ModelFilterComponent } from './filters-panel/model-filter/model-filter.component';
import { TypeFilterComponent } from './filters-panel/type-filter/type-filter.component';
import { SelectedFilterComponent } from './filters-panel/selected-filter/selected-filter.component';
import { RouteResolverService } from './route-resolver.service';
import { GroupOneComponent } from './filters-panel/group-one/group-one.component';
import { GroupTwoComponent } from './filters-panel/group-two/group-two.component';
import { GroupThreeComponent } from './filters-panel/group-three/group-three.component';
import { GroupSelectedComponent } from './filters-panel/group-selected/group-selected.component';
import { FiltersPanelComponent } from './filters-panel/filters-panel.component';

const searchRoutes: Routes = [
{
  path: ':app/:appId/:group/:groupId',
  component: FiltersPanelComponent,
  resolve: {
    route: RouteResolverService
  }
}, {
  path: ':query/:app/:appId/:group/:groupId',
  component: FiltersPanelComponent,
  resolve: {
    route: RouteResolverService
  }
}];

const routes: Routes =
[
  {
    path: '',
    redirectTo: '/en/1/0/1/0',
    pathMatch: 'full'
  }, {
    path: '',
    component: EmptyComponent
  }, {
    path: ':lang',
    component: MainPanelComponent,
    children: searchRoutes,
    resolve: {
      route: RouteResolverService
    }
  }, {
    path: '**',
    redirectTo: '/en/1/0/1/0',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [RouteResolverService]
})
export class AppRoutingModule { }
