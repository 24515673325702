import { Component,
  OnDestroy,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';

import { MediaMatcher } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';

import { State } from './store/reducer';
import * as SidenavActions from './store/actions';
import { TranslateService } from '@ngx-translate/core';
import { Router, RoutesRecognized } from '@angular/router';
import { SharedService } from './shared.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  title = 'Web Catalog';
  public toggle = true;
  loadingArticles = true;
  icon = 'menu';
  constructor(private store: Store<State>,
    changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
    media: MediaMatcher,
    translate: TranslateService,
    private router: Router,
    private sharedService: SharedService) {

      this.store.select('articles').subscribe((state: any) => {
        this.zone.run(() => {
          this.loadingArticles = state.loadingArticles;
        });
      });

      translate.addLangs(['en', 'de']);

      this.router.events.subscribe((value) => {
        if (value instanceof RoutesRecognized) {
          if (value.state.root.firstChild.params.lang !== translate.currentLang) {
            translate.use(value.state.root.firstChild.params.lang);
          }
        }
      });
      translate.setDefaultLang('en');

      this.mobileQuery = media.matchMedia('(max-width: 959px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.sharedService.sidebarClosed$.subscribe(isClosed => {
      this.icon = isClosed ? 'menu' : 'close';
    });
  }



  toggleSideNav(): void {
    const isClosed = this.icon === 'menu';
    this.store.dispatch({ type: SidenavActions.ActionTypes.TOGGLE_SIDENAV, payload: { sidenav: !isClosed } });
    this.sharedService.setSidebarClosed(!isClosed);
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
