import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute,
  Router
} from '@angular/router';

import { Store } from '@ngrx/store';

import { State } from '../../store/reducer';

import * as ArticlesActions from '../../store/actions';
import { Filter } from '../../models/filter.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-group-one',
  templateUrl: './group-one.component.html',
  styleUrls: ['./group-one.component.scss']
})
export class GroupOneComponent implements OnInit {

  allGroups = [];
  query = '';
  private app = Filter.getAggregationAndId('app');
  maxNumber = environment.defaultItemsNumber;
  private defaultMaxNumber = environment.defaultItemsNumber;
  showMoreButton = true;
  showLessButton = false;
  @Input() isMobile: boolean;

  constructor(private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles) {
        if (!state.togglingSidenav
          && state.result.Aggregations.ArticleGroupAggregationItem.BaseItems.length !== 0
          && state.result.Aggregations.ArticleGroupAggregationItem.AggregationLevel === 1) {

          this.app = Filter.getAggregationAndId('app');

          Filter.resetValue('Group1');

          if (Filter.Offset === 0) {
            this.allGroups = [];

            Object.assign(this.allGroups, state.result.Aggregations.ArticleGroupAggregationItem.BaseItems);

            this.maxNumber = this.defaultMaxNumber;
            if (this.allGroups.length < this.maxNumber) {
              this.maxNumber = this.allGroups.length;
            }
          }
          this.showMoreButton = true;

          this.hideExtensionButtons();

          this.store.dispatch({type: ArticlesActions.ActionTypes.CHANGE_FILTERS, payload: {
            filters: Filter
          }});
        } else if (!state.togglingSidenav
          && state.result.Aggregations.ArticleGroupAggregationItem.BaseItems.length === 0
          && state.result.Aggregations.ArticleGroupAggregationItem.AggregationLevel === 1) {
            this.allGroups = [];

            this.store.dispatch({type: ArticlesActions.ActionTypes.CHANGE_FILTERS, payload: {
              filters: Filter
            }});
          }
      }
    });
  }

  navigateDown(item: any) {
    if (Filter.Query.length) {
      this.router.navigate([Filter.Query.join(' '), this.app.lvl, this.app.id, '2', item.Id], {relativeTo: this.route.parent});
    } else {
      this.router.navigate([this.app.lvl, this.app.id, '2', item.Id], {relativeTo: this.route.parent});
    }
  }

  showMore(show: boolean) {
    if (show) {
      this.maxNumber = this.allGroups.length;
    } else {
      this.maxNumber = this.defaultMaxNumber;
    }
    this.showLessButton = show;
    this.showMoreButton = !show;
    this.hideExtensionButtons();
  }

  hideExtensionButtons() {
    if (this.allGroups.length <= this.maxNumber) {
      this.maxNumber = this.allGroups.length;
      this.showMoreButton = false;
      this.showLessButton = false;
    }
  }

}
