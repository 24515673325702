import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { State } from '../store/reducer';

import * as ArticlesActions from '../store/actions';
import { Filter, BaseType } from '../models/filter.model';
import { environment } from 'src/environments/environment';
import { MediaMatcher } from '@angular/cdk/layout';
import { forEach } from '@angular/router/src/utils/collection';

export interface BreadcrumbFilter extends BaseType {
  Type: number; // 1 - make, 2 - model, 3 - type
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  removable = true;

  filters: BreadcrumbFilter[] = [];
  groupfilters: BreadcrumbFilter[] = [];
  queryfilters: string[] = [];

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher) {

      this.mobileQuery = media.matchMedia(environment.mediaMatcher);
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);

      const me = this;

      this.store.select('filters').subscribe((state: any) => {
        if (state.changeFilters) {
          me.addApplicationsFilters();
          me.addGroupsFilters();
          me.addQueryFilters();

          this.store.dispatch({type: ArticlesActions.ActionTypes.FILTERS_CHANGED, payload: {
            filters: Filter
          }});
        }
      });

    }

  ngOnInit() {
  }

  addGroupsFilters(): void {
    if (Filter.Group1.Name && Filter.Group1.Id) {
      this.pushFilterItem(this.groupfilters, 'Group1', 1);
    } else {
      this.groupfilters.splice(0);
    }
    if (Filter.Group2.Name && Filter.Group2.Id) {
      this.pushFilterItem(this.groupfilters, 'Group2', 2);
    } else {
      this.groupfilters.splice(1);
    }
    if (Filter.Group3.Name && Filter.Group3.Id) {
      this.pushFilterItem(this.groupfilters, 'Group3', 3);
    } else {
      this.groupfilters.splice(2);
    }
  }

  addApplicationsFilters(): void {
    if (Filter.Make.Name && Filter.Make.Id) {
      this.pushFilterItem(this.filters, 'Make', 1);
    } else {
      this.filters.splice(0);
    }
    if (Filter.Model.Name && Filter.Model.Id) {
      this.pushFilterItem(this.filters, 'Model', 2);
    } else {
      this.filters.splice(1);
    }
    if (Filter.Type.Name && Filter.Type.Id) {
      this.pushFilterItem(this.filters, 'Type', 3);
    } else {
      this.filters.splice(2);
    }
  }

  addQueryFilters(): void {
    this.queryfilters.splice(0);
    if (Filter.Query.length) {
      if (JSON.stringify(Filter.Query) !== JSON.stringify(this.queryfilters)) {
        for (let i = 0; i < Filter.Query.length; ++i) {
          decodeURIComponent(Filter.Query[i]);
        }
        this.queryfilters.push(...Filter.Query);
      }
    }
  }

  clearFilters(): void {
    Filter.resetFilters(false);
    this.navigate(true);
  }

  private pushFilterItem(table: BreadcrumbFilter[], type: string, level: number) {
    let it: BreadcrumbFilter;
    it = {
      Id: Filter[type].Id,
      Name: Filter[type].Name,
      Type: level
    };

    let exist = false;
    for (let i = 0; i < table.length; ++i) {
      if (table[i].Type === level) {
        exist = true;
        break;
      }
    }
    if (!exist) {
      table.push(it);
    }
  }

  remove(filter: BreadcrumbFilter): void {
    this.filters.splice(filter.Type - 1);
    this.navigate(false);
  }

  removeGroup(filter: BreadcrumbFilter): void {
    this.groupfilters.splice(filter.Type - 1);
    this.navigate(false);
  }

  removeWord(filter: string): void {
    this.queryfilters.splice(this.queryfilters.indexOf(filter), 1);
    this.navigate(false);
  }

  private navigate(clear: boolean): void {
    if (!clear) {
      if (this.queryfilters.length) {
        this.router.navigate([
          Filter.Language,
          this.queryfilters.join(' '),
          this.filters.length + 1,
          this.filters.length > 0 ? this.filters[(this.filters.length - 1)].Id : 0,
          this.groupfilters.length + 1,
          this.groupfilters.length > 0 ? this.groupfilters[(this.groupfilters.length - 1)].Id : 0
          ]);
      } else {
        this.router.navigate([
          Filter.Language,
          this.filters.length + 1,
          this.filters.length > 0 ? this.filters[(this.filters.length - 1)].Id : 0,
          this.groupfilters.length + 1,
          this.groupfilters.length > 0 ? this.groupfilters[(this.groupfilters.length - 1)].Id : 0
          ]);
      }
    } else {
      this.router.navigate(['/' + Filter.Language + '/1/0/1/0']);
    }
  }
}
