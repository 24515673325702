import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import { Router,
  ActivatedRoute
} from '@angular/router';
import { Store } from '@ngrx/store';

import { State } from '../store/reducer';
import { Filter } from '../models/filter.model';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  languages = environment.languages;
  selected;
  group: any;
  app: any;
  @Input() isHeader: boolean = false;

  constructor(
    private router: Router,
    private store: Store<State>,
    private route: ActivatedRoute) {

    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles && !state.filtersChanged) {
        this.selected = Filter.Language;
        this.group = Filter.getAggregationAndId('group');
        this.app = Filter.getAggregationAndId('app');
      }
    });
   }

  ngOnInit() {
  }

  changeLanguage(event: any) {
    Filter.setValue('Language', event.value);
    this.navigate();
  }

  navigate() {
    let parameters = [];
    if (Filter.Query.length > 0) {
      parameters = [
        Filter.Language,
        Filter.Query.join(' '),
        this.app.lvl,
        this.app.id,
        this.group.lvl,
        this.group.id
      ];
    } else {
      parameters = [
        Filter.Language,
        this.app.lvl,
        this.app.id,
        this.group.lvl,
        this.group.id
      ];
    }
    this.router.navigate(parameters);
  }

}
