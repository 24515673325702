export const environment = {
  production: true,
  url: window.location.origin + '/api/',
  defaultItemsNumber: 8,
  defaultOffset: 10,
  mediaMatcher: '(max-width: 959px)',
  languages: [{
      value: 'en',
      name: 'English'
    }, {
      value: 'de',
      name: 'Deutsch'
    }]
};
