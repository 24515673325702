// importy
import { Result } from '../models/result.model';
import { Filter } from '../models/filter.model';
import { ActionTypes,
    Actions
} from './actions';

// stan początkowy
const initState = {
    result: [],
    filters: Filter.get(),
    sidenav: true,
    togglingSidenav: true,
    loadingArticles: false,
    loadedArticles: false
};

const initFiltersState = {
    filtersChanged: false,
    changeFilters: false
};

// reducer ładowania artykułów
// funkcja(stan początkowy, akcja)
export function reducer(state: any = initState, action: Actions) {
    const s = [];
    switch (action.type) {
        case ActionTypes.LOAD_ARTICLES:
            Object.assign(s, state, {result: [], loadingArticles: true, loadedArticles: false, togglingSidenav: false});
            return Object.assign(s, action.payload);
        case ActionTypes.ARTICLES_LOADED:
            Object.assign(s, state, {loadingArticles: false, loadedArticles: true, togglingSidenav: false});
            return Object.assign([], s, action.payload);
        case ActionTypes.TOGGLE_SIDENAV:
            Object.assign(s, state, {loadingArticles: false, loadedArticles: false, togglingSidenav: true});
            return Object.assign([], s, action.payload);
        default:
            return state;
    }
}

export function filtersReducer(state: any = initFiltersState, action: Actions) {
    const s = [];
    switch (action.type) {
    case ActionTypes.FILTERS_CHANGED:
        Object.assign(s, state, {filtersChanged: true, changeFilters: false});
        return Object.assign([], s, action.payload);
    case ActionTypes.CHANGE_FILTERS:
        Object.assign(s, state, {filtersChanged: false, changeFilters: true});
        return Object.assign([], s, action.payload);
    default:
        return state;
    }
}

export interface State {
  result: Result;
  filters: any;
  sidenav: boolean;
  togglingSidenav: boolean;
  loadingArticles: boolean;
  loadedArticles: boolean;
}
