import { Injectable } from '@angular/core';
import { Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';

import { map,
  take
} from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { State } from './store/reducer';
import * as ArticlesActions from './store/actions';

import { Filter } from './models/filter.model';


@Injectable()
export class RouteResolverService implements Resolve<any> {
  constructor(private store: Store<State>
    ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {

    Filter.setValuesBasedOnParamMap(route.paramMap);
    if (!route.firstChild) {
      if (route.paramMap.has('app')
        && route.paramMap.get('app') === '1'
        && route.paramMap.get('appId') === '0') {
        Filter.resetValue('Make');
        Filter.resetValue('Model');
        Filter.resetValue('Type');
        Filter.resetValue('Offset');
      }
      if (route.paramMap.has('group')
        && route.paramMap.get('group') === '1'
        && route.paramMap.get('groupId') === '0') {
        Filter.resetValue('Group1');
        Filter.resetValue('Group2');
        Filter.resetValue('Group3');
        Filter.resetValue('Offset');
      }
      if (!route.paramMap.has('query')) {
        Filter.resetValue('Query');
      }

      this.store.dispatch({type: ArticlesActions.ActionTypes.LOAD_ARTICLES, payload: { filters: Filter }});
    }

  }
}
