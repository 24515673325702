import { Component,
  OnInit,
  Input
} from '@angular/core';
import { ActivatedRoute,
  Router,
  ParamMap
} from '@angular/router';

import { Store } from '@ngrx/store';

import { State } from '../../store/reducer';

import * as ArticlesActions from '../../store/actions';
import { Filter } from '../../models/filter.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-type-filter',
  templateUrl: './type-filter.component.html',
  styleUrls: ['./type-filter.component.scss']
})
export class TypeFilterComponent implements OnInit {
  allTypes = [];
  query = '';
  make = {
    Name: '',
    Id: 0
  };
  model = {
    Name: '',
    Id: 0
  };
  @Input() isMobile: boolean;

  private group = Filter.getAggregationAndId('group');
  maxNumber = environment.defaultItemsNumber;
  private defaultMaxNumber = environment.defaultItemsNumber;
  showMoreButton = true;
  showLessButton = false;

  constructor(private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles && !state.changeFilters) {
        if (!state.togglingSidenav
          && state.result.Aggregations.ApplicationsAggregationItem.BaseItems.length !== 0
          && state.result.Aggregations.ApplicationsAggregationItem.AggregationLevel === 3) {
          this.group = Filter.getAggregationAndId('group');

          if (!state.filters.Make.Name) {
            Filter.setFilterFromItemGrandParent(state, 'Make', 'applications');
          }
          if (!state.filters.Model.Name) {
            Filter.setFilterFromItemParent(state, 'Model', 'applications');
          }

          this.make = Filter.getValue('Make');
          this.model = Filter.getValue('Model');

          Filter.resetValue('Type');
          if (Filter.Offset === 0) {
            this.allTypes = [];

            Object.assign(this.allTypes, state.result.Aggregations.ApplicationsAggregationItem.BaseItems);

            this.maxNumber = this.defaultMaxNumber;

            this.hideExtensionButtons();
          }

          this.store.dispatch({type: ArticlesActions.ActionTypes.CHANGE_FILTERS, payload: {
            filters: Filter
          }});
        }
      }
    });
  }

  navigateUp() {
    if (Filter.Query.length) {
      this.router.navigate([Filter.Query.join(' '), '2', this.make.Id, this.group.lvl, this.group.id], {relativeTo: this.route.parent});
    } else {
      this.router.navigate(['2', this.make.Id, this.group.lvl, this.group.id], {relativeTo: this.route.parent});
    }
  }

  navigateDown(item: any) {
    if (Filter.Query.length) {
      this.router.navigate([Filter.Query.join(' '), '4', item.Id, this.group.lvl, this.group.id], {relativeTo: this.route.parent});
    } else {
      this.router.navigate(['4', item.Id, this.group.lvl, this.group.id], {relativeTo: this.route.parent});
    }
  }

  showMore(show: boolean) {
    if (show) {
      this.maxNumber = this.allTypes.length;
    } else {
      this.maxNumber = this.defaultMaxNumber;
    }
    this.showLessButton = show;
    this.showMoreButton = !show;
    this.hideExtensionButtons();
  }

  hideExtensionButtons() {
    if (this.allTypes.length <= this.maxNumber) {
      this.maxNumber = this.allTypes.length;
      this.showMoreButton = false;
      this.showLessButton = false;
    }
  }
}
