import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private sidebarClosedSource = new BehaviorSubject<boolean>(true);
  sidebarClosed$ = this.sidebarClosedSource.asObservable();

  setSidebarClosed(isClosed: boolean) {
    this.sidebarClosedSource.next(isClosed);
  }
}
