import {
  Component,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { Router,
  ActivatedRoute,
  ResolveEnd
} from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material';
import { MediaMatcher } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';

import { State } from '../store/reducer';

import { Filter } from '../models/filter.model';
import { environment } from 'src/environments/environment';
import { ArticleService } from '../article.service';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  providers: [ArticleService]
})
export class SearchbarComponent implements OnDestroy {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  fg: FormGroup;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  private group = Filter.getAggregationAndId('group');
  private app = Filter.getAggregationAndId('app');
  options: string[];

  constructor(private fb: FormBuilder,
    private router: Router,
    private store: Store<State>,
    private route: ActivatedRoute,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private articleService: ArticleService) {
      this.mobileQuery = media.matchMedia(environment.mediaMatcher);
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);

      this.router.events.subscribe((value) => {
        if (value instanceof ResolveEnd) {
          const val = value.state.root.children[0].children[0].params.query;
          if (val) {
            const query = this.fg.get('query').value;
            if (!query || query !== val) {
              this.fg.get('query').setValue(Filter.removeWhitespace(decodeURIComponent(val)).join(' '));
            }
          } else {
            this.fg.get('query').setValue('');
          }
        }
      });

    this.createForm();

    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles && !state.filtersChanged) {
        this.group = Filter.getAggregationAndId('group');
        this.app = Filter.getAggregationAndId('app');
      }
    });
  }

  homeButton() {
    Filter.resetFilters(false);

    this.router.navigate(['/' + Filter.Language + '/1/0/1/0'], { relativeTo: this.route });
  }

  createForm() {
    this.fg = this.fb.group({
      query: ['']
    });
  }

  getErrorMessage() {
    return this.fg.get('query').hasError('required') ? 'This field is required.' : '';
  }

  search() {
    if (this.autocomplete.panelOpen) {
      this.autocomplete.closePanel();
    }
    if (this.fg.get('query').value !== '') {
      Filter.setValue('Query', this.fg.get('query').value.split(' '));
    } else {
      Filter.setValue('Query', []);
    }
    this.navigate();
  }

  query(event) {
    if (event.target.value !== '' && event.target.value !== ' ') {
      this.articleService.getAutoComplete(event.target.value).subscribe(data => {
        this.options = data.json();
      });
    }
  }

  navigate() {
    let parameters = [];
    if (Filter.Query.length) {
      parameters = [
        Filter.Language,
        Filter.Query.join(' '),
        this.app.lvl,
        this.app.id,
        this.group.lvl,
        this.group.id
      ];
    } else {
      parameters = [
        Filter.Language,
        this.app.lvl,
        this.app.id,
        this.group.lvl,
        this.group.id
      ];
    }
    this.router.navigate(parameters, { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
