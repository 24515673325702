import { Component,
  OnInit,
  NgZone,
  Input
} from '@angular/core';
import { ActivatedRoute,
  Router
} from '@angular/router';

import { Store } from '@ngrx/store';

import { State } from '../../store/reducer';

import * as ArticlesActions from '../../store/actions';
import { Filter } from '../../models/filter.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-make-filter',
  templateUrl: './make-filter.component.html',
  styleUrls: ['./make-filter.component.scss']
})
export class MakeFilterComponent implements OnInit {
  allMakes = [];
  query = '';
  private group = Filter.getAggregationAndId('group');
  maxNumber = environment.defaultItemsNumber;
  private defaultMaxNumber = environment.defaultItemsNumber;
  showMoreButton = true;
  showLessButton = false;
  @Input() isMobile: boolean;

  constructor(private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone) {
  }

  ngOnInit() {
    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles) {
        if (!state.togglingSidenav
          && state.result.Aggregations.ApplicationsAggregationItem.BaseItems.length !== 0
          && state.result.Aggregations.ApplicationsAggregationItem.AggregationLevel === 1) {
          this.zone.run(() => {
            this.group = Filter.getAggregationAndId('group');

            Filter.resetValue('Make');

            if (Filter.Offset === 0) {
              this.allMakes = [];

              Object.assign(this.allMakes, state.result.Aggregations.ApplicationsAggregationItem.BaseItems);

              this.maxNumber = this.defaultMaxNumber;
              this.hideExtensionButtons();
            }

            this.store.dispatch({type: ArticlesActions.ActionTypes.CHANGE_FILTERS, payload: {
              filters: Filter
            }});
          });
        } else if (!state.togglingSidenav
          && state.result.Aggregations.ApplicationsAggregationItem.BaseItems.length === 0
          && state.result.Aggregations.ApplicationsAggregationItem.AggregationLevel === 1) {
            this.allMakes = [];

            this.store.dispatch({type: ArticlesActions.ActionTypes.CHANGE_FILTERS, payload: {
              filters: Filter
            }});
          }
      }
    });
  }

  navigateDown(item: any) {
    if (Filter.Query.length) {
      this.router.navigate([Filter.Query.join(' '), '2', item.Id, this.group.lvl, this.group.id], {relativeTo: this.route.parent});
    } else {
      this.router.navigate(['2', item.Id, this.group.lvl, this.group.id], {relativeTo: this.route.parent});
    }
  }

  showMore(show: boolean) {
    if (show) {
      this.maxNumber = this.allMakes.length;
    } else {
      this.maxNumber = this.defaultMaxNumber;
    }
    this.showLessButton = show;
    this.showMoreButton = !show;
    this.hideExtensionButtons();
  }

  hideExtensionButtons() {
    if (this.allMakes.length <= this.maxNumber) {
      this.maxNumber = this.allMakes.length;
      this.showMoreButton = false;
      this.showLessButton = false;
    }
  }
}
