import { Component,
  OnInit,
  Input
} from '@angular/core';
import { ActivatedRoute,
  Router,
  ParamMap
} from '@angular/router';

import { Store } from '@ngrx/store';

import { State } from '../../store/reducer';

import * as ArticlesActions from '../../store/actions';
import { Filter } from '../../models/filter.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-group-three',
  templateUrl: './group-three.component.html',
  styleUrls: ['./group-three.component.scss']
})
export class GroupThreeComponent implements OnInit {

  allGroups = [];
  query = '';
  parent = {
    Name: '',
    Id: 0
  };
  viewValue = {
    Name: '',
    Id: 0
  };
  @Input() isMobile: boolean;

  private app = Filter.getAggregationAndId('app');
  maxNumber = environment.defaultItemsNumber;
  private defaultMaxNumber = environment.defaultItemsNumber;
  showMoreButton = true;
  showLessButton = false;

  constructor(private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles) {
        if (!state.togglingSidenav
          && state.result.Aggregations.ArticleGroupAggregationItem.BaseItems.length !== 0
          && state.result.Aggregations.ArticleGroupAggregationItem.AggregationLevel === 3) {

          this.app = Filter.getAggregationAndId('app');

          if (state.result.Aggregations.ArticleGroupAggregationItem.BaseItems[0].Parent.Parent) {
            if (!state.filters.Group2.Name) {
              Filter.setFilterFromItemParent(state, 'Group2', 'groups');
              Filter.setFilterFromItemGrandParent(state, 'Group1', 'groups');
            }

            this.parent = Filter.getValue('Group1');
            this.viewValue = Filter.getValue('Group2');

            if (Filter.Offset === 0) {
              this.allGroups = [];

              Object.assign(this.allGroups, state.result.Aggregations.ArticleGroupAggregationItem.BaseItems);

              this.maxNumber = this.defaultMaxNumber;
              if (this.allGroups.length < this.maxNumber) {
                this.maxNumber = this.allGroups.length;
              }
            }
          }

          if (!state.result.Aggregations.ArticleGroupAggregationItem.BaseItems[0].Parent.Parent) {
            if (!state.filters.Group2.Name) {
              Filter.setFilterFromItemProper(state, 'Group2', 'groups');
              Filter.setFilterFromItemParent(state, 'Group1', 'groups');
            }
            this.parent = Filter.getValue('Group1');
            this.viewValue = Filter.getValue('Group2');
          }

          this.showMoreButton = true;

          this.hideExtensionButtons();

          Filter.resetValue('Group3');

          this.store.dispatch({type: ArticlesActions.ActionTypes.CHANGE_FILTERS, payload: {
            filters: Filter
          }});
        }
      }
    });
  }

  navigateUp() {
    if (Filter.Query.length) {
      this.router.navigate([Filter.Query.join(' '), this.app.lvl, this.app.id, '2', this.parent.Id], {relativeTo: this.route.parent});
    } else {
      this.router.navigate([this.app.lvl, this.app.id, '2', this.parent.Id], {relativeTo: this.route.parent});
    }
  }

  navigateDown(item: any) {
    if (Filter.Query.length) {
      this.router.navigate([Filter.Query.join(' '), this.app.lvl, this.app.id, '4', item.Id], {relativeTo: this.route.parent});
    } else {
      this.router.navigate([this.app.lvl, this.app.id, '4', item.Id], {relativeTo: this.route.parent});
    }
  }

  showMore(show: boolean) {
    if (show) {
      this.maxNumber = this.allGroups.length;
    } else {
      this.maxNumber = this.defaultMaxNumber;
    }
    this.showLessButton = show;
    this.showMoreButton = !show;
    this.hideExtensionButtons();
  }

  hideExtensionButtons() {
    if (this.allGroups.length <= this.maxNumber) {
      this.maxNumber = this.allGroups.length;
      this.showMoreButton = false;
      this.showLessButton = false;
    }
  }

}
