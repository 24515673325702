// główny moduł aplikacji, tutaj się importuje customowe moduły i podpina w imports

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule,
  FormsModule
} from '@angular/forms';
import { HttpModule } from '@angular/http';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { HttpClientModule,
  HttpClient
} from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// wymagane dla material design
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

// customowy moduł
import { MaterialModule } from './material';

// nasze komponenty
import { SearchbarComponent } from './searchbar/searchbar.component';
import { HeaderComponent } from './header/header.component';
import { FiltersPanelComponent } from './filters-panel/filters-panel.component';
import { ArticlesPanelComponent } from './articles-panel/articles-panel.component';
import { MainPanelComponent } from './main-panel/main-panel.component';
import { EmptyComponent } from './empty/empty.component';

import { reducer, filtersReducer } from './store/reducer';
import { ModelFilterComponent } from './filters-panel/model-filter/model-filter.component';
import { TypeFilterComponent } from './filters-panel/type-filter/type-filter.component';
import { MakeFilterComponent } from './filters-panel/make-filter/make-filter.component';
import { SelectedFilterComponent } from './filters-panel/selected-filter/selected-filter.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { GroupOneComponent } from './filters-panel/group-one/group-one.component';
import { GroupTwoComponent } from './filters-panel/group-two/group-two.component';
import { GroupThreeComponent } from './filters-panel/group-three/group-three.component';
import { GroupSelectedComponent } from './filters-panel/group-selected/group-selected.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FooterComponent } from './footer/footer.component';
import { FooterMobileComponent } from './footer-mobile/footer-mobile.component';
import { LanguageComponent } from './language/language.component';

import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    SearchbarComponent,
    HeaderComponent,
    FiltersPanelComponent,
    ArticlesPanelComponent,
    MainPanelComponent,
    EmptyComponent,
    ModelFilterComponent,
    TypeFilterComponent,
    MakeFilterComponent,
    SelectedFilterComponent,
    LoadingSpinnerComponent,
    GroupOneComponent,
    GroupTwoComponent,
    GroupThreeComponent,
    GroupSelectedComponent,
    BreadcrumbComponent,
    FooterComponent,
    FooterMobileComponent,
    LanguageComponent,
    FilterPipe
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpModule,
    HttpClientModule,
    ScrollDispatchModule,
    StoreModule.forRoot({ articles: reducer, filters: filtersReducer }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  exports: [TranslateModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.txt');
}
