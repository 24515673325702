import { ParamMap } from '@angular/router';

export class BaseType {
    Id?: number;
    Name?: string;
}


class Filters {
    Query?: string[];
    Make?: BaseType;
    Model?: BaseType;
    Type?: BaseType;
    Group1?: BaseType;
    Group2?: BaseType;
    Group3?: BaseType;
    Offset?: number;
    Limit?: number;
    Language?: string;
    private __default: any;
    private __lastSuccess: any;

    constructor() {
        this.Query = [];
        this.Make = {};
        this.Model = {};
        this.Type = {};
        this.Group1 = {};
        this.Group2 = {};
        this.Group3 = {};
        this.Offset = 0;
        this.Limit = 10;
        this.Language = 'en';
        this.__default = {
            Query : [],
            Make : {},
            Model : {},
            Type : {},
            Group1 : {},
            Group2 : {},
            Group3 : {},
            Offset : 0,
            Limit : 10,
            Language : 'en'
        };
        this.__lastSuccess = {
            Query : [],
            Make : {},
            Model : {},
            Type : {},
            Group1 : {},
            Group2 : {},
            Group3 : {},
            Offset : 0,
            Limit : 10,
            Language : 'en'
        };
    }

    get(): any {
        return this;
    }

    getValue(key: string): any {
        return this[key];
    }

    getAggregationAndId(key: string): any {
        switch (key) {
            case 'group':
                if (this.Group3.Id) {
                    return {id: this.Group3.Id, lvl: 4};
                }
                if (this.Group2.Id) {
                    return {id: this.Group2.Id, lvl: 3};
                }
                if (this.Group1.Id) {
                    return {id: this.Group1.Id, lvl: 2};
                }
                return {id: 0, lvl: 1};
            case 'app':
                if (this.Type.Id) {
                    return {id: this.Type.Id, lvl: 4};
                }
                if (this.Model.Id) {
                    return {id: this.Model.Id, lvl: 3};
                }
                if (this.Make.Id) {
                    return {id: this.Make.Id, lvl: 2};
                }
                return {id: 0, lvl: 1};
            default:
                break;
        }
    }

    setSuccessfulSearch(): void {
        this.__lastSuccess = {
            Query: this.Query,
            Make: this.Make,
            Model: this.Model,
            Type: this.Type,
            Group1: this.Group1,
            Group2: this.Group2,
            Group3: this.Group3,
            Offset: 0,
            Limit: 10,
            Language: this.Language
        };
    }

    getLastSuccessfulSearch(): any[] {
        let successful = [this.Language];
        // if (this.__lastSuccess.Query) {
        this.setValue('Query', this.__lastSuccess.Query);
        successful.push(this.__lastSuccess.Query.join(' ').replace('/', '%2F'));
        // }
        if (this.__lastSuccess.Type.Id) {
            this.setValue('Type', this.__lastSuccess.Type);
            successful.push('4', this.__lastSuccess.Type.Id);
        } else if (this.__lastSuccess.Model.Id) {
            this.setValue('Model', this.__lastSuccess.Model);
            successful.push('3', this.__lastSuccess.Model.Id);
        } else if (this.__lastSuccess.Make.Id) {
            this.setValue('Make', this.__lastSuccess.Make);
            successful.push('2', this.__lastSuccess.Make.Id);
        } else {
            successful.push('1', '0');
        }
        if (this.__lastSuccess.Group3.Id) {
            this.setValue('Group3', this.__lastSuccess.Group3);
            successful.push('4', this.__lastSuccess.Group3.Id);
        } else if (this.__lastSuccess.Group2.Id) {
            this.setValue('Group2', this.__lastSuccess.Group2);
            successful.push('3', this.__lastSuccess.Group2.Id);
        } else if (this.__lastSuccess.Group1.Id) {
            this.setValue('Group1', this.__lastSuccess.Group1);
            successful.push('2', this.__lastSuccess.Group1.Id);
        } else {
            successful.push('1', '0');
        }
        return successful;
    }

    setValue(key: string, value: any): any {
        if (this.hasOwnProperty(key)) {
            this[key] = value;
            return this;
        }
    }

    resetFilters(resetLanguage: boolean): any {
        this.Query = [];
        this.Make = {};
        this.Model = {};
        this.Type = {};
        this.Group1 = {};
        this.Group2 = {};
        this.Group3 = {};
        this.Offset = 0;
        this.Limit = 10;
        if (resetLanguage) {
            this.Language = 'en';
        }
    }

    resetValue(key: string): any {
        if (this.hasOwnProperty(key)) {
            this[key] = this.__default[key];
            return this;
        }
    }

    setFilterFromItemProper(state: any, key: string, aggregationType: string): void {
        if (aggregationType === 'applications') {
            this[key] = {
                Id: state.result.Aggregations.ApplicationsAggregationItem.BaseItems[0].Id,
                Name: state.result.Aggregations.ApplicationsAggregationItem.BaseItems[0].Name
            };
        }
        if (aggregationType === 'groups') {
            this[key] = {
                Id: state.result.Aggregations.ArticleGroupAggregationItem.BaseItems[0].Id,
                Name: state.result.Aggregations.ArticleGroupAggregationItem.BaseItems[0].Name
            };
        }
    }

    setFilterFromItemParent(state: any, key: string, aggregationType: string): void {
        if (aggregationType === 'applications') {
            this[key] = {
                Id: state.result.Aggregations.ApplicationsAggregationItem.BaseItems[0].Parent.Id,
                Name: state.result.Aggregations.ApplicationsAggregationItem.BaseItems[0].Parent.Name
            };
        }
        if (aggregationType === 'groups') {
            this[key] = {
                Id: state.result.Aggregations.ArticleGroupAggregationItem.BaseItems[0].Parent.Id,
                Name: state.result.Aggregations.ArticleGroupAggregationItem.BaseItems[0].Parent.Name
            };
        }
    }

    setFilterFromItemGrandParent(state: any, key: string, aggregationType: string): void {
        if (aggregationType === 'applications') {
            this[key] = {
                Id: state.result.Aggregations.ApplicationsAggregationItem.BaseItems[0].Parent.Parent.Id,
                Name: state.result.Aggregations.ApplicationsAggregationItem.BaseItems[0].Parent.Parent.Name
            };
        }
        if (aggregationType === 'groups') {
            this[key] = {
                Id: state.result.Aggregations.ArticleGroupAggregationItem.BaseItems[0].Parent.Parent.Id,
                Name: state.result.Aggregations.ArticleGroupAggregationItem.BaseItems[0].Parent.Parent.Name
            };
        }
    }

    setValuesBasedOnParamMap(paramMap: ParamMap): void {
        if (paramMap.has('lang')) {
            this.setValue('Language', paramMap.get('lang'));
        }
        if (paramMap.has('query')) {
            this.setValue('Query', this.removeWhitespace(paramMap.get('query').split(' ')));
        }
        if (paramMap.has('app') && paramMap.get('app') === '4') {
            this.resetValue('Offset');
            this.setValue('Type', {
                Id: paramMap.get('appId')
            });
        }
        if (paramMap.has('app') && paramMap.get('app') === '3') {
            this.resetValue('Offset');
            this.resetValue('Type');
            this.setValue('Model', {
                Id: paramMap.get('appId')
            });
        }
        if (paramMap.has('app') && paramMap.get('app') === '2') {
            this.resetValue('Offset');
            this.resetValue('Type');
            this.resetValue('Model');
            this.setValue('Make', {
                Id: paramMap.get('appId')
            });
        }
        if (paramMap.has('group') && paramMap.get('group') === '4') {
            this.resetValue('Offset');
            this.setValue('Group3', {
                Id: paramMap.get('groupId')
            });
        }
        if (paramMap.has('group') && paramMap.get('group') === '3') {
            this.resetValue('Offset');
            this.resetValue('Group3');
            this.setValue('Group2', {
                Id: paramMap.get('groupId')
            });
        }
        if (paramMap.has('group') && paramMap.get('group') === '2') {
            this.resetValue('Offset');
            this.resetValue('Group3');
            this.resetValue('Group2');
            this.setValue('Group1', {
                Id: paramMap.get('groupId')
            });
        }
    }

    removeWhitespace(value) {
        if (typeof(value) === 'string') {
            value = value.split(' ');
        }
        for (let i = 0; i < value.length; ++i) {
            if (value[i] === '') {
                value.splice(i, 1);
                --i;
            }
        }
        return value;
    }
}

export let Filter = new Filters();
