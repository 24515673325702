import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  b2bPhoto = 'assets/b2b.png';
  tecdocPhoto = 'assets/tecdoc.svg';
  tuvPhoto = 'assets/tuv.svg';
  arrowUpRightPhoto = 'assets/arrow-up-right.svg';
  facebookPhoto = 'assets/facebook.svg';
  instagramPhoto = 'assets/instagram.svg';
  linkedinPhoto = 'assets/linkedin.svg';
  youtubePhoto = 'assets/youtube.svg';
  year = new Date().getFullYear();


  constructor() { }

  ngOnInit() {
  }

}
