import { Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  NgZone,
  HostBinding,
  HostListener
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav,
  MatSidenavContainer
} from '@angular/material';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { Router,
  ActivatedRoute,
  ParamMap
} from '@angular/router';

import { Store } from '@ngrx/store';

import { State } from '../store/reducer';

import * as ArticlesActions from '../store/actions';
import { Filter } from '../models/filter.model';
import { environment } from '../../environments/environment';
import { SharedService } from '../shared.service';


@Component({
  selector: 'app-main-panel',
  templateUrl: './main-panel.component.html',
  styleUrls: ['./main-panel.component.scss']
})
export class MainPanelComponent implements OnInit, OnDestroy, AfterViewInit  {
  @ViewChild('snav', {read: MatSidenav}) sidenav: MatSidenav;
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  @ViewChild(CdkScrollable) scrollable: CdkScrollable;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  hideFiltersPanel = false;
  private articlesNumber;

  @HostBinding('class.is-mobile') get isMobile() {
    return this.mobileQuery.matches;
  }

  constructor(private store: Store<State>,
    private zone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private sharedService: SharedService) {

    this.mobileQuery = media.matchMedia(environment.mediaMatcher);
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    if (!this.mobileQuery.matches) {
      const target = event.target as Document;
      const { documentElement } = target;

      if (documentElement.scrollHeight - documentElement.scrollTop === documentElement.clientHeight) {
        this.loadMoreArticles();
      }
    }
  }

  loadMoreArticles() {
    if (this.articlesNumber > 0 && this.articlesNumber % environment.defaultOffset === 0) {
      Filter.Offset += environment.defaultOffset;
      this.store.dispatch({ type: ArticlesActions.ActionTypes.LOAD_ARTICLES, payload: { filters: Filter } });
    }
  }

  ngAfterViewInit () {
    this.store.select('articles').subscribe((state: any) => {
      if (this.mobileQuery.matches
        && state.togglingSidenav) {
          this.zone.run(() => {
            this.sidenav.toggle();
            setTimeout(() => {}, 0);
          });
      }
      if (state.loadedArticles) {
        this.articlesNumber = state.result.Articles.length;
        this.hideFiltersPanel = false;
        if (state.result.Articles.length === 0
          && (state.result.Aggregations.ArticleGroupAggregationItem.BaseItems.length === 0
          || state.result.Aggregations.ApplicationsAggregationItem.BaseItems.length === 0)) {
          this.zone.run(() => {
            this.hideFiltersPanel = true;
            this.sidenav.toggle(false);
            setTimeout(() => {}, 0);
          });
        } else if (Filter.Offset === 0) {
          Filter.setSuccessfulSearch();
          this.zone.run(() => {
            this.sidenav.toggle(true);
            setTimeout(() => {}, 0);
          });
        }
      }
    });
    this.scrollable.elementScrolled().subscribe((e: any) => {
      if (e.srcElement.scrollTop > e.srcElement.scrollHeight - e.srcElement.offsetHeight - 1
        && this.articlesNumber > 0
        && this.articlesNumber % environment.defaultOffset === 0) {
        Filter.Offset += environment.defaultOffset;
        this.store.dispatch({type: ArticlesActions.ActionTypes.LOAD_ARTICLES, payload: {
          filters: Filter
        }});
      }
    });
  }

  onSidenavClosedStart(): void {
    this.zone.run(() => {
      this.sharedService.setSidebarClosed(true); 
    });
  }

  onSidenavOpenedStart(): void {
    this.zone.run(() => {
      this.sharedService.setSidebarClosed(false); 
    });
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
