import { Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from '../store/reducer';
import { ActivatedRoute,
  Router
} from '@angular/router';
import { Filter } from '../models/filter.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.scss']
})
export class FiltersPanelComponent implements OnInit, OnDestroy {

  appLevel = 1;
  groupLevel = 1;
  showApps = false;
  showGroups = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  constructor(private store: Store<State>,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher) {
      this.mobileQuery = media.matchMedia(environment.mediaMatcher);
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }

  ngOnInit() {

    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles) {
          this.appLevel = state.result.Aggregations.ApplicationsAggregationItem.AggregationLevel;
          this.groupLevel = state.result.Aggregations.ArticleGroupAggregationItem.AggregationLevel;
          if (state.result.Aggregations.ApplicationsAggregationItem.BaseItems.length !== 0) {
            this.showApps = true;
          } else {
            this.showApps = false;
          }
          if (state.result.Aggregations.ArticleGroupAggregationItem.BaseItems.length !== 0) {
            this.showGroups = true;
          } else {
            this.showGroups = false;
          }
        }
    });

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
