import { Action } from '@ngrx/store';

export enum ActionTypes {
    LOAD_ARTICLES = 'LOAD_ARTICLES',
    ARTICLES_LOADED = 'ARTICLES_LOADED',
    TOGGLE_SIDENAV = 'TOGGLE_SIDENAV',
    FILTERS_CHANGED = 'FILTERS_CHANGED',
    CHANGE_FILTERS = 'CHANGE_FILTERS'
}

export class LoadArticles implements Action {
    readonly type: ActionTypes.LOAD_ARTICLES;
    constructor(public payload: any) {}
}

export class ArticlesLoaded implements Action {
    readonly type: ActionTypes.ARTICLES_LOADED;
    constructor(public payload: any) {}
}

export class ToggleSidenav implements Action {
    readonly type: ActionTypes.TOGGLE_SIDENAV;
    constructor(public payload: any) {}
}

export class FiltersChanged implements Action {
    readonly type: ActionTypes.FILTERS_CHANGED;
    constructor(public payload: any) {}
}

export class ChangeFilters implements Action {
    readonly type: ActionTypes.CHANGE_FILTERS;
    constructor(public payload: any) {}
}

export type Actions = LoadArticles | ArticlesLoaded | ToggleSidenav | FiltersChanged | ChangeFilters;
