import { Component, OnInit } from '@angular/core';
import { Router,
  ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute) {
    }

  ngOnInit() {
    this.router.navigate(['/en/1/0/1/0'], {relativeTo: this.route});
  }

}
