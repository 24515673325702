import { Component,
  OnInit,
  Input
} from '@angular/core';
import { ActivatedRoute,
  Router,
  ParamMap
} from '@angular/router';

import { Store } from '@ngrx/store';

import { State } from '../../store/reducer';

import * as ArticlesActions from '../../store/actions';
import { Filter } from '../../models/filter.model';

@Component({
  selector: 'app-group-selected',
  templateUrl: './group-selected.component.html',
  styleUrls: ['./group-selected.component.scss']
})
export class GroupSelectedComponent implements OnInit {
  parent = {
    Name: '',
    Id: 0
  };
  viewValue = {
    Name: '',
    Id: 0
  };
  private app = Filter.getAggregationAndId('app');
  @Input() isMobile: boolean;

  constructor(private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.store.select('articles').subscribe((state: any) => {
      if (state.loadedArticles) {
        if (!state.togglingSidenav
          && state.result.Aggregations.ArticleGroupAggregationItem.BaseItems.length !== 0
          && state.result.Aggregations.ArticleGroupAggregationItem.AggregationLevel === 4) {
          this.app = Filter.getAggregationAndId('app');
          if (!state.filters.Group1.Name) {
            Filter.setFilterFromItemGrandParent(state, 'Group1', 'groups');
          }
          if (!state.filters.Group2.Name) {
            Filter.setFilterFromItemParent(state, 'Group2', 'groups');
          }
          if (!state.filters.Group3.Name) {
            Filter.setFilterFromItemProper(state, 'Group3', 'groups');
          }

          this.parent = Filter.getValue('Group2');
          this.viewValue = Filter.getValue('Group3');

          this.store.dispatch({type: ArticlesActions.ActionTypes.CHANGE_FILTERS, payload: {
            filters: Filter
          }});
        }
      }
    });
  }

  navigateUp() {
    if (Filter.Query.length) {
      this.router.navigate([Filter.Query.join(' '), this.app.lvl, this.app.id, '3', this.parent.Id], {relativeTo: this.route.parent});
    } else {
      this.router.navigate([this.app.lvl, this.app.id, '3', this.parent.Id], {relativeTo: this.route.parent});
    }
  }

}
