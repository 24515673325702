import { Component,
  OnInit,
  Injectable,
  NgZone,
  ChangeDetectorRef
} from '@angular/core';

// importy do store i reducera
import { Store } from '@ngrx/store';
import { State } from '../store/reducer';
import { MediaMatcher } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { Filter } from '../models/filter.model';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-articles-panel',
  templateUrl: './articles-panel.component.html',
  styleUrls: ['./articles-panel.component.scss']
})

export class ArticlesPanelComponent implements OnInit {

  articles$: Array<any> = [];
  loadingArticles = true;
  noPhotoUrl: string;
  mobileQuery: MediaQueryList;
  showBackButtons = false;
  private _mobileQueryListener: () => void;

  constructor(
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher) {

      this.mobileQuery = media.matchMedia(environment.mediaMatcher);
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);

      this.noPhotoUrl = 'assets/nophoto_opaque.png';
  }

  ngOnInit(): void {
    this.store.select('articles').subscribe((state: any) => {
      this.loadingArticles = state.loadingArticles;
      if (state.loadedArticles
        && !state.togglingSidenav) {
        if (state.filters.Offset === 0) {
          this.articles$ = [];
          Object.assign(this.articles$, state.result.Articles);
        } else {
          this.zone.run(() => {
            this.articles$.push(...state.result.Articles);
            setTimeout(() => {}, 0);
          });
        }
        if (state.result.Articles.length === 0
          && state.result.Aggregations.ArticleGroupAggregationItem.BaseItems.length === 0
          && state.result.Aggregations.ApplicationsAggregationItem.BaseItems.length === 0) {
            this.showBackButtons = true;
          } else {
            this.showBackButtons = false;
          }
      }
    });
  }

  backButton(fullBack: boolean): void {
    if (fullBack) {
      Filter.resetFilters(false);
      this.router.navigate(['/' + Filter.Language + '/1/0/1/0']);
    } else {
      const params = '/' + Filter.getLastSuccessfulSearch().join('/');
      this.router.navigate([params], { relativeTo: this.route.firstChild });
    }
  }
}
