// plik do importowania modułów material design do aplikacji głównej

import { MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatChipsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatAutocompleteTrigger,
    MatIconRegistry
} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatChipsModule,
    MatSelectModule,
    MatAutocompleteModule
  ],
  exports: [
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatChipsModule,
    MatSelectModule,
    MatAutocompleteModule
  ]
})
export class MaterialModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'chevron-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron-down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron-up.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-left.svg')
    );
  }
}
